.section {
    p, .description {
        font-weight: 400;
    }
}

.section-dark {
    p {
        color: #d8d8d8;
    }

    h1, h2, h3 {   
        color: #f0f0f0;
    }
}

ul li {
    font-weight: 400;
}

.ul-style-type-none {
    list-style-type: none;
}
